import { Spacer } from "~/widgets/spacer";
import { CloseModalButton, useModal } from "../modal";
import { createEffect, createSignal, Show } from "solid-js";
import { updatePartnerUserProfile } from "~/server/apis/client_apis";
import { ThreeDotLoader } from "~/widgets/button";

type UserInfoModalProps = {
  initiateOrder: () => void;
};

export default function UserInfoModal(props: UserInfoModalProps) {
  const { themeMode } = useModal()!;
  const [name, setName] = createSignal<string>("");
  const [email, setEmail] = createSignal<string>("");
  const [phone, setPhone] = createSignal<string>("");
  const [error, setError] = createSignal<string | null>(null);
  const { setIsModalOn, updateModalContentGetter } = useModal()!;
  const [showLoader, setShowLoader] = createSignal<boolean>(false);

  let nameInput: HTMLInputElement | undefined;
  let emailInput: HTMLInputElement | undefined;
  let phoneInput: HTMLInputElement | undefined;

  const updateUserInfo = async () => {
    setShowLoader(true);
    try {
      const res = await updatePartnerUserProfile({
        firstName: name().split(" ")[0],
        lastName: name().split(" ").slice(1).join(" "),
        email: email(),
        phoneNumber: phone(),
      });
      setIsModalOn(false);
      updateModalContentGetter(() => <></>);
      props.initiateOrder();
    } catch (e: any) {
      setError(e.message ?? "Something went wrong");
    }
    setShowLoader(false);
  };

  createEffect(() => {
    if (name() && email() && phone()) {
      setError(null);
    }
  });

  const errorStrip = (condition: boolean | undefined) => {
    return (
      <Show when={condition}>
        <div class="py-1 text-f12Bold text-errorDark">{error()}</div>
      </Show>
    );
  };

  return (
    <div class="my-auto flex h-fit w-full flex-col rounded-t-2xl border border-neutral-200 font-[Inter]   dark:border-basePrimaryDark  lg:rounded-b-2xl">
      <div
        class="relative flex h-fit flex-col items-start rounded-[15px]"
        style={{
          background: "linear-gradient(180deg, #E7EFF2 0%, #FFF 24.29%)",
        }}
      >
        <div class="absolute right-4 top-6 lg:right-2 lg:top-2">
          <CloseModalButton />
        </div>
        <div class="w-full p-4 ">
          <div class="text-h4 text-basePrimaryDark">Just one more step</div>
          <div class="text-medium text-textNormal">
            Please fill the information
          </div>
          <div class="flex flex-col gap-4 py-5">
            <div>
              <label class="text-mediumBold text-textDark">Your name</label>
              <input
                ref={nameInput}
                type="text"
                class={`w-full rounded-[12px] border  px-4 py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal dark:border-basePrimaryDark ${
                  error()?.includes("name")
                    ? "border-errorDark"
                    : "border-baseTertiaryDark"
                }`}
                placeholder="Enter full name"
                onInput={(e) => setName(e.currentTarget.value)}
              />
              <Show when={error()?.includes("name")}>
                <div class="py-1 text-f12Bold text-errorDark">{error()}</div>
              </Show>
            </div>
            <div>
              <label class="text-mediumBold text-textDark">Your email</label>
              <input
                ref={emailInput}
                type="email"
                class={`w-full rounded-[12px] border  px-4 py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal disabled:border-green-200 dark:border-basePrimaryDark ${
                  error()?.includes("email")
                    ? "border-errorDark"
                    : "border-baseTertiaryDark"
                }`}
                placeholder="Enter your email ID"
                onInput={(e) => setEmail(e.currentTarget.value)}
              />
              {errorStrip(error()?.includes("email"))}
            </div>
            <div>
              <label class="text-mediumBold text-textDark">Phone no.</label>
              <input
                ref={phoneInput}
                type="tel"
                class={`w-full rounded-[12px] border  px-4 py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal dark:border-basePrimaryDark ${
                  error()?.includes("phone")
                    ? "border-errorDark"
                    : "border-baseTertiaryDark"
                }`}
                placeholder="10 digit phone no."
                onInput={(e) => {
                  const inputValue = e.currentTarget.value;
                  const numericValue = inputValue.replace(/\D/g, "");
                  setPhone(numericValue);
                  e.currentTarget.value = numericValue;
                }}
              />
              {errorStrip(error()?.includes("phone"))}
            </div>
          </div>
          <div class="text-f12 text-textNormal">
            Your privacy is our priority. We don’t share your data
          </div>
          {errorStrip(error() === "Please fill all the fields")}
        </div>
        <div class="h-px  w-full bg-neutral-200 dark:bg-basePrimaryDark"></div>
        <div class="w-full p-4">
          <button
            class={`flex h-12 w-full items-center justify-center rounded-full bg-basePrimaryDark py-3 text-f16Bold text-white disabled:cursor-not-allowed disabled:bg-gray-200 disabled:py-4 disabled:text-baseSecondaryMedium`}
            disabled={showLoader() || !!error()}
            onClick={() => {
              if (showLoader() || error()) return;
              if (!name() || !email() || !phone()) {
                setError("Please fill all the fields");
                return;
              } else {
                updateUserInfo();
              }
            }}
          >
            <Show
              when={!showLoader()}
              fallback={
                <div class="flex h-6 content-center items-center justify-center">
                  <ThreeDotLoader color="#999" />
                </div>
              }
            >
              Continue
            </Show>
          </button>
        </div>
      </div>
    </div>
  );
}
